import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { LanguageTranslateService } from 'src/app/services/language-translate.service';
import { SettingsService } from 'src/app/services/settings.service';

@Component({
  selector: 'app-menu-sidebar',
  templateUrl: './menu-sidebar.component.html',
  styleUrls: ['./menu-sidebar.component.css']
})
export class MenuSidebarComponent implements OnInit {
lang:any="en"
  currentLanguage$?: Subscription
  translations: any;
  user_type:any;
  constructor(
    private authService: AuthService,
    private langService: LanguageTranslateService,
    private router: Router,
    private settingService:SettingsService
  ) { 

     
  }
  routerChange(routeString: string) {
    this.lang = this.langService.getLangLocalStorage() ?? 'en';
    this.router.navigate([this.lang, routeString]);
  }
  logout() {
    this.authService.logout();
    this.settingService.clearCompanyDetails()
  }
  isActive(routeString: string): boolean {
    const url = this.router.url.split("/");
    
    if (url[2] == routeString) {
      return true;
    }
    return false;
  }
  ngOnInit(): void {
    if (this.authService.getUserDetails()) {
      this.user_type =  this.authService.getUserDetails()?.user_type;
    }
    else{
      this.logout();
    }
    this.currentLanguage$ = this.langService.currentLanguage$.subscribe(async () => {
    this.lang = this.langService.getLangLocalStorage();
      return new Promise<boolean>(async (resolve, reject) => {
        await this.langService.loadTranslations('layout', 'MenuSidebarComponent')?.then((res: any) => {
          this.translations = res;
          resolve(true);
        }).catch((err: any) => {
          console.error(`Failed to load translations`, err);
          reject(false);
        });
      })
    });
  }
  ngOnDestroy() {
    this.currentLanguage$?.unsubscribe();
  }
}
