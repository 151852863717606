import { Injectable } from '@angular/core';
import { WebService } from './web.service';

@Injectable({
  providedIn: 'root'
})
export class ProService {
  lang:any = 'en';

  constructor(
    private webService: WebService
  ) { }

  updatePROVerification(payload: any) {
    return this.webService.postProFile(
      'pro/pro-visa-verification',
      payload
    );
  }
  proGetEmployeeList(payload: any) {
    return this.webService.post(
      'pro/get-employee-list',
      payload
    );
  }
  getEmployeeDetails(payload: any) {
    return this.webService.post(
      'pro/get-employee-details',
      payload
    );
  }
  
  getEmployeeByStatus(payload: any) {
    return this.webService.post(
      'pro/get-employee-list-by-status',
      payload
    );
  }
  viewSingleImg(payload: any) {
    return this.webService.post(
      'pro/single-view-image',
      payload
    );
  }
  getSystemDocumentList(payload: any) {
    return this.webService.post(
      'pro/employee-file-document-list',
      payload
    );
  }
  getUserDocumentList(payload: any) {
    return this.webService.post(
      'pro/get-document-master',
      payload
    );
  }
  getDocumentDetails(payload: any) {
    return this.webService.post(
      'pro/employee-file-document-list-details',
      payload
    );
  }
  getEmployeeMaster() {
    return this.webService.post(
      'pro/get-employee-master',
      {}
    );
  }
  getEmployeeCountByStatus() {
    return this.webService.post(
      'pro/get-employee-count-by-status',
      {}
    );
  }
}
