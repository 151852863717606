import { Injectable } from '@angular/core';
import { HttpClient, HttpHandler } from '@angular/common/http';
import * as Global from 'src/app/shared/globals';
import { firstValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Response } from '../http-handeler/interfaces/response'
@Injectable({
  providedIn: 'root'
})
export class WebService {
  readonly BASE_URL!:string;
  readonly BASE_HREF!:string;
  readonly BASE_ATS_URL!:string;
  readonly BASE_HREF_ATS!:string;

  constructor(
    private http:HttpClient
  ) 
  { 
    this.BASE_URL = environment.apiUrl;
    this.BASE_HREF = environment.BASEHREF;
    this.BASE_ATS_URL = environment.apiAtsUrl;
    this.BASE_HREF_ATS = environment.BASEHREF_ATS

  }

  get(url:string){
    return this.http.get<any>(`${this.BASE_URL}/${this.BASE_HREF}/${url}`)
  }
// Ats
  getAts(url:string, options:any){  
    console.log(options)
    return this.http.get<any>(`${this.BASE_ATS_URL}/${this.BASE_HREF_ATS}/${url}`, options)
  }
  simpleGetAts(url:string){
    return this.http.get<any>(`${this.BASE_ATS_URL}/${this.BASE_HREF_ATS}/${url}`)
  }

   postAts(url:string, payload:any){
    return this.http.post<any>(`${this.BASE_ATS_URL}/${this.BASE_HREF_ATS}/${url}`, payload)
    
    // .then((response:Response)=>{
    //   if (response?.status !== 'success') {
    //     if (response?.val_msg) {
    //       const {val_msg} = response;
    //       for (const key in val_msg) {
    //         if (Object.prototype.hasOwnProperty.call(val_msg, key)) {
    //           const element:any = val_msg[key];
    //           if (element.message) {
    //             return this.handleError(
    //               element.message || 'Something went wrong!'
    //             )
    //           }
              
    //         }
    //       }
    //       return 'Validation Error: Please check all the fields correctly';
    //     }
    //     return this.handleError(
    //       response?.error || response?.message || response?.code || 'Something went wrong!'
    //     );
    //   }
    //   return response;
    // }).catch(this.handleError)
  }

  postAtsFormData(url: string, payload: any) {
    var formData = new FormData();
    for (var key in payload) {
      formData.append(key, payload[key]);
    }
    // return this.http.post<any>(`${this.Base_Url}/${url}`, formData);
    return this.http.post<any>(`${this.BASE_ATS_URL}/${this.BASE_HREF_ATS}/${url}`, payload)
  }
  updateAts(url:string, payload:any){
    return this.http.put<any>(`${this.BASE_ATS_URL}/${this.BASE_HREF_ATS}/${url}`, payload)
  }


  // Ats End

  async post(url:string, payload:any){
    return await firstValueFrom(
      this.http.post<any>(`${this.BASE_URL}/${this.BASE_HREF}/${url}`, payload)
    ).then((response:Response)=>{
      if (response?.status !== 'success') {
        if (response?.val_msg) {
          const {val_msg} = response;
          for (const key in val_msg) {
            if (Object.prototype.hasOwnProperty.call(val_msg, key)) {
              const element:any = val_msg[key];
              if (element.message) {
                return this.handleError(
                  element.message || 'Something went wrong!'
                )
              }
              
            }
          }
          return 'Validation Error: Please check all the fields correctly';
        }
        return this.handleError(
          response?.error || response?.message || response?.code || 'Something went wrong!'
        );
      }
      return response;
    }).catch(this.handleError)
  }
  postFormData(url: string, payload: any) {
    var formData = new FormData();
    for (var key in payload) {
      formData.append(key, payload[key]);
    }
    // return this.http.post<any>(`${this.Base_Url}/${url}`, formData);
    return this.post(url, formData);
  }
  
  postProFile(url:any,payload:any){
    const formData = new FormData();

    // Append primitive fields (employee_id & pro_id)
    formData.append('employee_id', payload.employee_id);
    formData.append('pro_status', payload.pro_status);
  
    // Process `steps`
    Object.keys(payload.steps).forEach((stepKey) => {
      const stepData = payload.steps[stepKey];
  
      // Append non-file fields
      Object.keys(stepData).forEach((key) => {
        if (key !== 'documents' && typeof stepData[key] !== 'object') {
          formData.append(`steps[${stepKey}][${key}]`, stepData[key]);
        }
      });
  
      // Handle nested "validity" fields
      if (stepData.validity) {
        formData.append(`steps[${stepKey}][validity][from]`, stepData.validity.from || '');
        formData.append(`steps[${stepKey}][validity][to]`, stepData.validity.to || '');
      }
  
      // Handle document files
      if (stepData.documents && Array.isArray(stepData.documents)) {
        stepData.documents.forEach((doc:any, index:any) => {
          if (doc.file) {
            formData.append(`steps[${stepKey}][documents][${index}][name]`, doc.name);
            formData.append(`steps[${stepKey}][documents][${index}][file]`, doc.file);
          }
        });
      }
    });

    return this.post(url, formData);

  }
  postVendor(url:any,payload:any){
    const formData = new FormData();

    // Append non-file fields
    Object.keys(payload).forEach(key => {
      if (
        key !== 'companyCertifications' &&
        key !== 'memorandumOfAssociationFile' &&
        key !== 'establishmentIdFileAdd' &&
        key !== 'tradeLicenseFile' &&
        key !== 'companyLogo' &&
        key !== 'powerOfAttorneyFile'
      ) {
        if (typeof payload[key] === 'object' && payload[key] !== null) {
          formData.append(key, JSON.stringify(payload[key])); // Convert objects to JSON string
        } else {
          formData.append(key, payload[key]); // Append normal fields
        }
      }
    });

    // Append Binary File Fields
    console.log(payload,'jjj');

    if (payload.memorandumOfAssociationFile) {
      console.log('memon');
      
      formData.append('memorandumOfAssociationFile', payload.memorandumOfAssociationFile);
    }

    if (payload.tradeLicenseFile) {
      formData.append('tradeLicenseFile', payload.tradeLicenseFile);
    }
    if (payload.companyLogo) {
      formData.append('companyLogo', payload.companyLogo);
    }

    if (payload.establishmentIdFile) {
      formData.append('establishmentIdFile', payload.establishmentIdFile);
    }
    if (payload.powerOfAttorneyFile) {
      formData.append('powerOfAttorneyFile', payload.powerOfAttorneyFile);
    }

    // Append Company Certifications Files
    if (payload.companyCertifications && payload.companyCertifications.length > 0) {
      payload.companyCertifications.forEach((cert: any, index: number) => {
        if (cert.file) {
          formData.append(`companyCertifications[${index}]`, cert.file);
          formData.append(`companyCertifications[${index}].name`, cert.name);
        }
      });
    }
    return this.post(url, formData);
  }
  postMultiFile(url: any, submit_data: any) {
    // const webservice_path = Global.BACKEND_URL;
    let form_data = new FormData();
    console.log(submit_data);
    
    for (const key in submit_data) {
      console.log(key,'key------');
      
      if (submit_data.hasOwnProperty(key)) {
        // console.log(submit_data[key], 'arr d');
        if (Array.isArray(submit_data[key])) {
          // Handle multiple files for the 'submit_data' key
          const files: any = submit_data[key];
          for (let i = 0; i < files.length; i++) {
            console.log(files,'filess====',key);
            
            form_data.append(`${key}`, files[i].file, files[i].name);
          }
        } else {
          form_data.append(key, submit_data[key]);
        }
      }
    }
    return this.post(url, form_data);
  }
  private handleError(error: any): Promise<any> {
    if (error.status === 0) {
      return Promise.reject<string>('Something went wrong');
    }
    // if (error.status) {
    //   if (error.status === 401) {
    //     localStorage.clear();
    //     window.location.href = '/';
    //     return Promise.reject<string>(error.statusText + '! No user found with your token');
    //   }
    //   if (error.status === 403) {
    //     localStorage.clear();
    //     window.location.href = '/';
    //     return Promise.reject<string>('Your are logged Out');
    //   }
    //   return Promise.reject<string>(error?.error?.message);
    // }
    // if (
    //   (error.message) ||
    //   error == 'No user found with your token'
    // ) {
    //   localStorage.clear();
    //   window.location.href = '/';
    // }
    return Promise.reject<string>(error.message || error);
  }
  simpleGet(url: string, payload: Object = {}) {
    return this.http.post(`${this.BASE_URL}/${this.BASE_HREF}/${url}`, payload, { responseType: 'blob', observe: 'response' });
  }
}
