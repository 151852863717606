

<div class="borderredius-0">
    <div class="p-15 modal-header bg-light border-bottom">
        <div class="modal-text">
            <h3 class="derktext left-line static-text mb-0">{{translations?.viewImage}}</h3>
        </div>
        <span class="ms-auto cursor" (click)="downloadImage()"><i class="bi bi-download"></i>Download</span>
        <button class="btn-close btn ms-3" (click)="closeDialog()"><i class="bi bi-x-lg"></i></button>
    </div>
    <div class="p-15 modal-body border-bottom m">
        <div class="risection">
            <div class="iframe-container">
                <iframe class="pdf" style="border: none; overflow: hidden;" [src]="imagePath" width="700" [style.height.px]="500" [style.overflow-x]="'hidden'" [style.overflow-y]="'hidden'" height="500"></iframe>
                <!-- <img [src]="imagePath" alt="" class="w-100 h-100"> -->
            </div>
        </div>
    </div>
</div>