<div class="borderredius-0">
    <div class="p-15 modal-header bg-light border-bottom">
        <div class="modal-text">
            <h3 class="derktext left-line static-text mb-0">{{translations?.viewComLogo}}</h3>
        </div>
        <button class="btn-close btn ms-4 ms-auto" (click)="closeDialog()"><i class="bi bi-x-lg"></i></button>
    </div>
    <div class="p-15 modal-body border-bottom m">
        <div class="risection" *ngIf="data?.fileName.includes('.pdf')">
                <div class="iframe-container">
                <iframe class="pdf" style="border: none; overflow: hidden;" [src]="imagePath" width="700" [style.height.px]="500" [style.overflow-x]="'hidden'" [style.overflow-y]="'hidden'" height="500"></iframe>
                <!-- <img [src]="imagePath" alt="" class="w-100 h-100"> -->
            </div>
        </div>
        <div class="risection" *ngIf="!data?.fileName.includes('.pdf')">
            <div class="d-flex justify-content-center">
                <img [src]="imagePath"  alt="" class="w-100 img-fluid h-100">
            </div>
        </div>
    </div>
</div>
