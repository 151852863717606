<div class="app-content leave-cms">

<app-menu-sidebar></app-menu-sidebar>

<div class="app-header topfinance-nav">
    <h3 class="me-5">{{translations?.dashboard}}</h3>
    <app-language class="ms-auto"></app-language>

    <!-- <div class="text-end">
        <ul class="dashrgt">
            <li>
                <div class="chartbox">
                    <span>Your profile is incomplete</span>
                    <div class="chartimg">
                        <em>95%</em>
                        <img src="assets/images/round.png" alt="Logo">
                    </div>
                </div>
            </li>
            <li>
                <ng-container *ngIf="company_logo; else noComlogo">
                    <div class="profileimg">
                        <img [src]="company_logo" alt="user">
                    </div>
                </ng-container>
                <ng-template #noComlogo>
                    <div class="profileimg">
                        <img src="assets/images/user.jpg" alt="user">
                    </div>
                </ng-template>
                
            </li>
        </ul>
    </div> -->
    <app-profile></app-profile>
</div>
<div class="" *ngIf="user?.user_type == 'company' || user?.user_type == 'staff'">
  <div class="row flex-nowrap g-0 leftdeshbord">
      <div class="col-sm-10 flex-nowrap height100">
          <div class="info-fluid">
              <div class="row">
                  <div class="col-12">
                      <div class="row g-4">
                          <div class="col-sm-6 col-xxl-3">
                              <div class="dashcard card" (click)="navigateToEmployees('active')">
                                  <div class="roundicon">
                                      <img src="assets/images/dicon8.png" alt="icon">
                                  </div>
                                  <div class="cardboxtxt">
                                      <h3>{{translations?.totalEmployees}}</h3>
                                      <span>{{(totalEmployees?.approved_emp_count + totalEmployees?.pending_emp_count + totalEmployees?.inactive_emp_count ) || 0}}</span>
                                  </div>
                                  <div class="abso">
                                      <img src="assets/images/dicon11.png" alt="icon">
                                  </div>
                              </div>
                          </div>
                          <div class="col-sm-6 col-xxl-3">
                              <div class="dashcard card" (click)="routeToPage('approval', 'leave-approval')">
                                  <div class="roundicon color1">
                                      <img src="assets/images/dicon6.png" alt="icon">
                                  </div>
                                  <div class="cardboxtxt">
                                      <h3>{{translations?.leaveApplication}}</h3>
                                      <span>{{totalEmployees?.employee_leaves?.length ?? 0}}</span>
                                  </div>
                                  <div class="abso">
                                      <img src="assets/images/dicon12.png" alt="icon">
                                  </div>
                              </div>
                          </div>
                          <div class="col-sm-6 col-xxl-3">
                              <div class="dashcard card">
                                  <div class="roundicon color2">
                                      <img src="assets/images/dicon7.png" alt="icon">
                                  </div>
                                  <div class="cardboxtxt">
                                      <h3>{{translations?.newEmpJoined}}</h3>
                                      <span class="static-text">{{newJoinedEmployees}}</span>
                                  </div>
                                  <div class="abso">
                                      <img src="assets/images/dicon10.png" alt="icon">
                                  </div>
                              </div>
                          </div>
                          <div class="col-sm-6 col-xxl-3">
                              <div class="dashcard card" (click)="navigateToEmployees('pending')">
                                  <div class="roundicon color3">
                                      <img src="assets/images/dicon9.png" alt="icon">
                                  </div>
                                  <div class="cardboxtxt">
                                      <h3>{{translations?.joiningApproval}}</h3>
                                      <span>{{totalEmployees?.pending_emp_count ?? 0}}</span>
                                  </div>
                                  <div class="abso">
                                      <img src="assets/images/dicon13.png" alt="icon">
                                  </div>
                              </div>
                          </div>
                      </div>
                      <div class="row">
                          <div class="col-xl-6 mt-4">
                              <div class="info_box card minheight">
                                  <div class="p-15 py-2 d-flex flex-wrap align-items-center border-bottom">
                                      <h6 class="mb-0 me-13 title">{{translations?.companyGrowth}}</h6>
                                      <div class="ms-auto" [formGroup]="filterForm" >
                                          <select formControlName="ctc_date_filter" (change)="getDashboardCTCData()" aria-label="Default select example" class="form-select cs_select cs_dark">
                                              <option *ngFor="let year of financialYearOptions" [value]="year.value">{{year?.label}}</option>
                                          
                                          </select>
                                      </div>
                                  </div>
                                  <div class="text-center">
                                    <ng-container *ngIf="ctcLoading">
                                        <div class="card-body flip-element canversmain">
                                            <canvas
                                         baseChart
                                         class="chart"
                                         [data]="barChartData2"
                                         [options]="barChartOptions2"
                                         [legend]="barChartLegend"
                                         [type]="barChartType"
                                         width="100"
                                         height="280"
                                         ></canvas>

                                        </div>
                                    </ng-container>
                                    <ng-container *ngIf="!ctcLoading">
                                        <div class="card-body flip-element canversmain">
                                            <canvas baseChart width="650" height="250" [data]="barChartData2"
                                                [options]="barChartOptions2" [plugins]="barChartPlugins"
                                                [legend]="barChartLegend" [type]="'line'">
                                            </canvas>

                                        </div>
                                    </ng-container>
                                      <!-- <img src="assets/images/chart.png" alt="chart" class="w-100"> -->
                                  </div>
                              </div>
                          </div>
                          <div class="col-xl-6 mt-4">
                            <div class="info_box card">
                                <div class="p-15 py-2 d-flex flex-wrap align-items-center border-bottom minhe">
                                    <h6 class="mb-0 me-13">{{translations?.recruitmentProg}}</h6>                                        
                                </div>
                                <div class="text-center">
                                    <div class="table-responsive table-left progresstable">
                                        <table class="table shift-allowance">
                                            <tbody>
                                              <tr>
                                                <td style="background-color: #F5F6FA; text-align: left; width: 12%;">Name</td>
                                                <td style="background-color: #F5F6FA; text-align: left;">Department</td>
                                                <td style="background-color: #F5F6FA; text-align: left;">Type</td>
                                                <td style="background-color: #F5F6FA; text-align: left;">Date & Time</td>
                                              </tr>
                                              <tr>
                                                <td style="text-align:left;"><span class="skeleton">Anjali Mekherjee</span></td>
                                                <td style="text-align:left;"><span class="skeleton">WEB DEVELOPER</span></td>
                                                <td style="text-align:left;"><span class="warningcolor colorround skeleton">Tech interview</span></td>
                                                <td style="text-align:left;"><span class="skeleton">14-05-2024 <span class="text-warning ms-1">10:30 AM</span></span></td>
                                              </tr>
                                              <tr>
                                                <td style="text-align:left;"><span class="skeleton">Anjali Mekherjee</span></td>
                                                <td style="text-align:left;"><span class="skeleton">WEB DEVELOPER</span></td>
                                                <td style="text-align:left;"><span class="primarycolor colorround skeleton">Tech interview</span></td>
                                                <td style="text-align:left;"><span class="skeleton">14-05-2024 <span class="text-warning ms-1">10:30 AM</span></span></td>
                                              </tr>
                                              <tr>
                                                <td style="text-align:left;"><span class="skeleton">Anjali Mekherjee</span></td>
                                                <td style="text-align:left;"><span class="skeleton">WEB DEVELOPER</span></td>
                                                <td style="text-align:left;"><span class="dangercolor colorround skeleton">Tech interview</span></td>
                                                <td style="text-align:left;"><span class="skeleton">14-05-2024 <span class="text-warning ms-1">10:30 AM</span></span></td>
                                              </tr>                                                 
                                              <tr>
                                                <td style="text-align:left;"><span class="skeleton">Anjali Mekherjee</span></td>
                                                <td style="text-align:left;"><span class="skeleton">WEB DEVELOPER</span></td>
                                                <td style="text-align:left;"><span class="warningcolor colorround skeleton">Tech interview</span></td>
                                                <td style="text-align:left;"><span class="skeleton">14-05-2024 <span class="text-warning ms-1">10:30 AM</span></span></td>
                                              </tr>
                                              <tr>
                                                <td style="text-align:left;"><span class="skeleton">Anjali Mekherjee</span></td>
                                                <td style="text-align:left;"><span class="skeleton">WEB DEVELOPER</span></td>
                                                <td style="text-align:left;"><span class="primarycolor colorround skeleton">Tech interview</span></td>
                                                <td style="text-align:left;"><span class="skeleton">14-05-2024 <span class="text-warning ms-1">10:30 AM</span></span></td>
                                              </tr>
                                              <tr>
                                                <td style="text-align:left;"><span class="skeleton">Anjali Mekherjee</span></td>
                                                <td style="text-align:left;"><span class="skeleton">WEB DEVELOPER</span></td>
                                                <td style="text-align:left;"><span class="dangercolor colorround skeleton">Tech interview</span></td>
                                                <td style="text-align:left;"><span class="skeleton">14-05-2024 <span class="text-warning ms-1">10:30 AM</span></span></td>
                                              </tr>
                                            </tbody>
                                        </table>
                                      </div>
                                </div>
                            </div>
                        </div>
                      </div>
                      <div class="row">
                          <div class="col-xl-6 col-xxl-3 mt-4">
                              <div class="info_box card minheight">
                                  <div class="p-15 py-2 d-flex flex-wrap align-items-center border-bottom minhe">
                                      <h6 class="mb-0 me-13 title">{{translations?.upcomingBirth}}</h6>                                        
                                  </div>
                                  <div class="card-body">
                                    <ng-container *ngFor="let emp of upcomingBirthdaysList">
                                    
                                    
                                      <div class="usdetails ng-star-inserted">
                                          <div class="user-box">
                                            <ng-container *ngIf="emp?.profile_pic; else noProfile">
                                                <div class="user-img ng-star-inserted">
                                                    <img [src]="emp?.profile_pic" alt="profile" class="w-100">
                                                   
                                                </div>
                                            </ng-container>
                                             <ng-template #noProfile>
                                                <div class="user-img ng-star-inserted">
                                                    <img src="assets/images/user-icon.jpg" alt="profile" class="w-100">
                                                </div>
                                             </ng-template>
                                              <div class="user-dtls">
                                            
                                                    <!-- <ng-container *ngIf="lang == 'ar'; else elseEngName">
                                                        <h4 class="">{{emp?.emp_first_name_uae ?? translations?.na}} {{emp?.emp_last_name_uae ?? translations?.na}}</h4>
                                                     </ng-container>
                                                     <ng-template #elseEngName>
                                                         <h4 class="">{{emp?.emp_first_name ?? translations?.na}} {{emp?.emp_last_name ?? translations?.na}}</h4>
                                                     </ng-template> -->
                                                     {{(lang === 'ar')? emp.emp_first_name_uae : emp.emp_first_name}}  {{(lang === 'ar')? emp.emp_last_name_uae : emp.emp_last_name}}

                                                  <!-- <h4 class="flip-element">{{emp?.emp_first_name ?? translations?.na}} {{emp?.emp_last_name ?? translations?.na}}</h4> -->
                                                  <h5 class="flip-element">{{emp?.designation ?? translations?.na}}</h5>
                                              </div>
                                          </div>
                                          <div [ngClass]="{'text-success':getEmpDate(emp?.dob) === 'Today', 'text-danger':getEmpDate(emp?.dob) === 'Tomorrow' }" class="acvc flip-element">{{getEmpDate(emp?.dob)}}</div>
                                      </div>
                                    </ng-container>

                                    <ng-container *ngIf="upcomingBirthdaysList?.length == 0 || upcomingBirthdaysList == undefined">
                                        <div class="usdetails d-flex justify-content-center">
                                            <div class="user-box">
                                                <div class="user-dtls">
                                                    <p class="flip-element">{{translations?.noUpcomigBirthday}}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-container>


                                  
                                  </div>
                              </div>
                              
                          </div>
                          <div class="col-xl-6 col-xxl-3 mt-4">
                              <div class="info_box card minheight">
                                  <div class="p-15 py-2 d-flex flex-wrap align-items-center border-bottom minhe">
                                      <h6 class="mb-0 me-13 title">{{translations?.empOnLeave}}</h6>
                                      <!-- <div class="ms-auto">
                                          <select aria-label="Default select example" class="form-select cs_select cs_dark">
                                              <option selected="">This Week</option>
                                              <option value="1">Monthly1</option>
                                              <option value="2">Monthly2</option>
                                              <option value="3">Monthly3</option>
                                          </select>
                                      </div> -->
                                  </div>
                                  <div class="card-body">

                                    <ng-container *ngFor="let emp of empLeaveList">

                                        <div class="usdetails ng-star-inserted">
                                            <div class="user-box">
                                                <ng-container *ngIf="emp?.profile_pic; else no_profile">
                                                    <div class="user-img ng-star-inserted">
                                                        <img [src]="emp?.profile_pic" alt="profile" class="w-100">
                                                    </div>
                                                </ng-container>
                                                <ng-template #no_profile>
                                                    <div class="user-img ng-star-inserted">
                                                        <img src="assets/images/user.png" alt="profile" class="w-100">
                                                    </div>
                                                </ng-template>
                                                <div class="user-dtls">
                                                    {{(lang === 'ar')? emp.emp_first_name_uae : emp.emp_first_name}}  {{(lang === 'ar')? emp.emp_last_name_uae : emp.emp_last_name}}
                                                    <!-- <h4 class="flip-element">{{emp?.emp_first_name}} {{emp?.emp_last_name}}</h4> -->
                                                    <h5 class="flip-element">{{emp?.designation ?? translations?.na}}</h5>
                                                </div>
                                            </div>
                                            <!-- <div [ngClass]="{'text-success':getEmpDate(emp?.leave_from_date) === 'Today', 'text-danger':getEmpDate(emp?.leave_from_date) === 'Tomorrow' }" class="acvc flip-element">{{getEmpDate(emp?.leave_from_date)}}</div> -->
                                        </div>
                                    </ng-container>
                                    <ng-container *ngIf="empLeaveList?.length == 0 || empLeaveList == undefined">
                                        <div class="usdetails d-flex justify-content-center">
                                            <div class="user-box">
                                                <div class="user-dtls">
                                                    <p class="flip-element">{{translations?.noUpcomigLeave}}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-container>
                                  </div>
                              </div>
                          </div>
                          <div class="col-xl-6 col-xxl-3 mt-4">
                              <div class="info_box card minheight">
                                  <div class="p-15 py-2 d-flex flex-wrap align-items-center border-bottom minhe">
                                      <h6 class="mb-0 me-13 title">{{translations?.lastTenFnf}}</h6>
                                  </div>
                                  <div class="card-body">
                                    <ng-container *ngFor="let emp of totalEmployees?.inactive_employees">
                                        <div class="usdetails ng-star-inserted">
                                            <div class="user-box">
                                              <ng-container *ngIf="emp?.profile_pic; else noProfile">
                                                  <div class="user-img ng-star-inserted">
                                                      <img [src]="emp?.profile_pic" alt="profile" class="w-100">
                                                     
                                                  </div>
                                              </ng-container>
                                               <ng-template #noProfile>
                                                  <div class="user-img ng-star-inserted">
                                                      <img src="assets/images/user-icon.jpg" alt="profile" class="w-100">
                                                  </div>
                                               </ng-template>
                                                <div class="user-dtls">
                                                    {{(lang === 'ar')? emp.emp_first_name_uae : emp.emp_first_name}}  {{(lang === 'ar')? emp.emp_last_name_uae : emp.emp_last_name}}
                                                    <!-- <h4 class="flip-element">{{emp?.emp_first_name ?? translations?.na}} {{emp?.emp_last_name ?? translations?.na}}</h4> -->
                                                    <h5 class="flip-element">{{emp?.emp_id ?? translations?.na}}</h5>
                                                </div>
                                            </div>
                                            <div [ngClass]="{'text-success':getEmpDate(emp?.date_of_exit) === 'Today', 'text-danger':getEmpDate(emp?.date_of_exit) === 'Tomorrow' }" class="acvc flip-element">
                                                <span> {{getEmpDate(emp?.date_of_exit)}} </span> <span class="text-dark">(LWD)</span>
                                            </div>
                                        </div>
                                    </ng-container>
                                    

                                    <ng-container *ngIf="totalEmployees?.inactive_employees?.length == 0 ||totalEmployees?.inactive_employees == undefined">
                                        <div class="usdetails d-flex justify-content-center">
                                            <div class="user-box">
                                                <div class="user-dtls">
                                                    <p class="flip-element">{{translations?.noData}}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-container>

                                      <!-- <div class="usdetails ng-star-inserted">
                                          <div class="user-box">
                                              <div class="user-img ng-star-inserted holicon">
                                                  <img src="assets/images/dicon.png" alt="profile">
                                              </div>
                                              <div class="user-dtls">
                                                  <h4 class="flip-element">Holi Festival</h4>
                                                  <h5 class="flip-element">Jan 14 2021</h5>
                                              </div>
                                          </div>
                                      </div> -->
                                     
                                  </div>
                              </div>
                          </div>
                          <div class="col-xl-6 col-xxl-3 mt-4">
                              <div class="info_box card minheight">
                                  <div class="p-15 py-2 d-flex flex-wrap align-items-center border-bottom minhe">
                                      <h6 class="mb-0 me-13 title">{{translations?.workAnniversary}}</h6>                                        
                                  </div>
                                  <div class="card-body">
                                    <ng-container *ngFor="let emp of upcomingWorkAnniversaryList">
                                      <div class="usdetails ng-star-inserted">
                                        <div class="user-box">
                                            <ng-container *ngIf="emp?.profile_pic; else no_profile">
                                                <div class="user-img ng-star-inserted">
                                                    <img [src]="emp?.profile_pic" alt="profile" class="w-100">
                                                </div>
                                            </ng-container>
                                            <ng-template #no_profile>
                                                <div class="user-img ng-star-inserted">
                                                    <img src="assets/images/user-icon.jpg" alt="profile" class="w-100">
                                                    
                                                </div>
                                           </ng-template>
                                            <div class="user-dtls">
                                                {{(lang === 'ar')? emp.emp_first_name_uae : emp.emp_first_name}}  {{(lang === 'ar')? emp.emp_last_name_uae : emp.emp_last_name}}
                                                <!-- <h4 class="flip-element">{{emp?.emp_first_name ?? translations?.na}} {{emp?.emp_last_name ?? translations?.na}}</h4> -->
                                                <h5 class="flip-element">{{emp?.designation ?? translations?.na}}</h5>
                                            </div>
                                        </div>
                                        <div [ngClass]="{'text-success':getEmpDate(emp?.doj) === 'Today', 'text-danger':getEmpDate(emp?.doj) === 'Tomorrow' }" class="acvc flip-element">{{getEmpDate(emp?.doj)}}</div>
                                    </div>
                                    </ng-container>
                                    <ng-container *ngIf="upcomingWorkAnniversaryList?.length == 0 || upcomingWorkAnniversaryList == undefined">
                                        <div class="usdetails d-flex justify-content-center">
                                            <div class="user-box">
                                                <div class="user-dtls">
                                                    <p class="flip-element">{{translations?.noUpcomingWork}}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-container>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
      <div class="col-sm-2 rightstyke height100">
          <div class="right-details height100">
              <div class="rhead d-flex align-items-center">
                  <h2>{{translations?.plan}}</h2>
                  <button class="btn button-solide btn-border px-3 ms-auto" (click)="fetchCreditSettings()">{{translations?.purchaseCreditBtnTxt}}</button>
              </div>                
              <div class="p-15 pt-0">
                  <div class="timebox">
                      <div class="timemain d-flex">
                          <div class="col">
                              <span>{{translations?.currPlan}}</span>
                              <strong>{{totalEmployees?.plan_name ?? translations?.na}}</strong>
                          </div>
                          <div class="col">
                              <span class="text-warning">{{translations?.currBalance}}</span>
                              <strong style="cursor: pointer;" (click)="openPaymentHistoryDialog()">{{creditBalance ?? 0}}</strong>
                          </div>
                      </div>
                  </div>
              </div>
              <ng-container  *ngIf="
              Global.checkCompanyModulePermission({company_module: 'announcement',
                company_sub_module: 'announcement',
                company_sub_operation: ['view'],
                company_strict: true
              }); else permission
             ">
              <div class="rhead d-flex flex-wrap align-items-center">
                  <h2>{{translations?.announcement}}</h2>
                  <a class="btn button-solide btn-border ms-auto" *ngIf="latestAnnouncementData?.length !== 0" (click)="routeToannounce()">{{translations?.viewAll}}</a>
              </div>
              <div class="card-body pt-0">
                  <ul class="annowithdate">
                    <ng-container *ngFor="let annc of latestAnnouncementData; let i = index">
                        <li class="cursor" (click)="routeToanncDetails(annc?._id)"><span class="dateann" [ngClass]="classArray[i % classArray.length]">  <b>{{ formatDate(annc.created_at).day }}</b>{{ formatDate(annc.created_at).month }}</span> <span class="describe">{{annc?.title}}</span></li>
                    </ng-container>

                    <p *ngIf="latestAnnouncementData?.length == 0 || latestAnnouncementData == undefined ">
                    {{translations?.noAnnonce}}
                    </p>
                     <!-- <li><span class="dateann border-green"><b>20</b>May</span> <span class="describe"> Many desktop publishing packages and web page editors now use Lorem Ipsum. </span></li> -->
                     <!-- <li><span class="dateann border-red"><b>05</b>May</span> <span class="describe">Lorem Ipsum is that it has a normal distribution. </span></li> -->
                  </ul>
              </div>
            </ng-container>
            <ng-template #permission>
            <app-permission></app-permission>
          </ng-template>
              <div class="rhead d-flex flex-wrap align-items-center">
                  <h2>{{translations?.notice}}</h2>
                  <a class="btn button-solide btn-border ms-auto" *ngIf="noticeData?.length !== 0" (click)="routeToNotice()">{{translations?.viewAll}}</a>
              </div>
              <div class="card-body pt-0">
                  <ul class="anulist">
                  <ng-container *ngFor="let notice of noticeData">
                    <li class="cursor" (click)="routeTonoticeDetails(notice?.emp_db_id, notice?.document_type_id)"><b> {{notice?.document_name}} </b>{{translations?.documentWillExpire}} <b> {{notice?.expire_in_days}} {{translations?.days}} </b> {{translations?.for}}
                    <b><span>{{(lang === 'ar')? notice.emp_first_name_uae : notice.emp_first_name}}  {{(lang === 'ar')? notice.emp_last_name_uae : notice.emp_last_name}}</span></b></li>
                </ng-container>
                <p *ngIf="noticeData?.length == 0 || noticeData == undefined ">
                    {{translations?.noNotice}}
                    </p>
                  </ul>
              </div>

              <div class="rhead d-flex flex-wrap align-items-center">
                  <h2>{{translations?.upcomingAppraisal}}</h2>
                  <!-- <a class="btn button-solide btn-border ms-auto" (click)="routeToAppraisal()">{{translations?.viewAll}}</a> -->
              </div>
              <div class="card-body pt-0">
                <ng-container *ngFor="let emp of upcomingWorkAnniversaryList">
                    <div class="usdetails ng-star-inserted">
                        <div class="user-box">
                            <ng-container *ngIf="emp?.profile_pic; else no_profile">
                                <div class="user-img ng-star-inserted">
                                    <img [src]="emp?.profile_pic" alt="profile" class="w-100">

                                   
                                </div>
                            </ng-container>
                            <ng-template #no_profile>
                                <div class="user-img ng-star-inserted">
                                    <img src="assets/images/user-icon.jpg" alt="profile" class="w-100">
                                </div>
                           </ng-template>
                            <div class="user-dtls">
                                {{(lang === 'ar')? emp.emp_first_name_uae : emp.emp_first_name}}  {{(lang === 'ar')? emp.emp_last_name_uae : emp.emp_last_name}}
                                <!-- <h4 class="flip-element">{{emp?.emp_first_name}} {{emp?.emp_last_name}}</h4> -->
                                <h5 class="flip-element">{{emp?.designation ?? translations?.na}}</h5>
                            </div>
                        </div>
                        <div [ngClass]="{'text-success':getEmmApprDate(emp?.doj) === 'Today', 'text-danger':getEmmApprDate(emp?.doj) === 'Tomorrow' }" class="acvc flip-element">{{getEmmApprDate(emp?.doj)}}</div>
                    </div>
                </ng-container>
                <ng-container *ngIf="upcomingWorkAnniversaryList?.length == 0 || upcomingWorkAnniversaryList == undefined">
                    <div class="usdetails d-flex justify-content-center">
                        <div class="user-box">
                            <div class="user-dtls">
                                <p class="flip-element">{{translations?.noUpcomingWork}}</p>
                            </div>
                        </div>
                    </div>
                </ng-container>
              
              </div>
          </div>
      </div>
  </div>
</div>
<div class="" *ngIf="user?.user_type == 'pro'">
  <div class="row flex-nowrap g-0 leftdeshbord">
      <div class="col-sm-10 flex-nowrap height100">
          <div class="info-fluid">
              <div class="row">
                  <div class="col-12">
                      <div class="row g-4">
                          <div class="col-sm-6 col-xxl-3">
                              <div class="dashcard card" (click)="navigateToEmployees('active')">
                                  <div class="roundicon">
                                      <img src="assets/images/dicon8.png" alt="icon">
                                  </div>
                                  <div class="cardboxtxt">
                                      <h3>Pending</h3>
                                      <span>{{proDashboardData?.pending}}</span>
                                  </div>
                                  <div class="abso">
                                      <img src="assets/images/dicon11.png" alt="icon">
                                  </div>
                              </div>
                          </div>
                          <div class="col-sm-6 col-xxl-3">
                              <div class="dashcard card" (click)="routeToPage('approval', 'leave-approval')">
                                  <div class="roundicon color1">
                                      <img src="assets/images/dicon6.png" alt="icon">
                                  </div>
                                  <div class="cardboxtxt">
                                      <h3>Inprogress</h3>
                                      <span>0</span>
                                  </div>
                                  <div class="abso">
                                      <img src="assets/images/dicon12.png" alt="icon">
                                  </div>
                              </div>
                          </div>
                          <div class="col-sm-6 col-xxl-3">
                              <div class="dashcard card">
                                  <div class="roundicon color2">
                                      <img src="assets/images/dicon7.png" alt="icon">
                                  </div>
                                  <div class="cardboxtxt">
                                      <h3>Approved</h3>
                                      <span class="static-text">{{proDashboardData?.approved}}</span>
                                  </div>
                                  <div class="abso">
                                      <img src="assets/images/dicon10.png" alt="icon">
                                  </div>
                              </div>
                          </div>
                          <div class="col-sm-6 col-xxl-3">
                              <div class="dashcard card" (click)="navigateToEmployees('pending')">
                                  <div class="roundicon color3">
                                      <img src="assets/images/dicon9.png" alt="icon">
                                  </div>
                                  <div class="cardboxtxt">
                                      <h3>Rejected</h3>
                                      <span>0</span>
                                  </div>
                                  <div class="abso">
                                      <img src="assets/images/dicon13.png" alt="icon">
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>

  </div>
</div>
</div>