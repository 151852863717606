import { DialogRef } from '@angular/cdk/dialog';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { LanguageTranslateService } from 'src/app/services/language-translate.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { EmployeeProfileService } from 'src/app/services/employee-profile.service';
import * as Global from 'src/app/shared/globals';

@Component({
  selector: 'app-view-image',
  templateUrl: './view-image.component.html',
  styleUrls: ['./view-image.component.css']
})
export class ViewImageComponent implements OnInit{
lang:any = 'en'

  // imagePath:any;
  currentLanguage$?:Subscription;
  translations:any;
  imagePath!: SafeResourceUrl;

  constructor(
    private dialogRef:MatDialogRef<ViewImageComponent>,
    private langService:LanguageTranslateService,
    private empProfileService: EmployeeProfileService,
    private sanitizer: DomSanitizer,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ){
    this.imagePath = this.sanitizer.bypassSecurityTrustResourceUrl(data?.image)
    // this.imagePath = data;
  }


  ngOnInit(): void {
    this.currentLanguage$ = this.langService.currentLanguage$.subscribe(async () => {
      this.lang = this.langService.getLangLocalStorage();
       return new Promise<boolean>(async (resolve, reject) => {
         await this.langService.loadTranslations('profile', 'employeeModal')?.then((res: any) => {
           this.translations = res;
          
           resolve(true);
         }).catch((err: any) => {
           console.error(`Failed to load translations`, err);
           reject(false);
         });
       })
     });
  }
  downloadImage(){
    this.empProfileService.singleDownloadImage({ 'image_path': this.data?.fileName }).then((res: any) => {
      if (res?.status == 'success') {
        Global.saveFiledoc(`Document`, res);
      }
    })
  }
  
  closeDialog(){
    this.dialogRef.close();
  }
  
}
