import { Injectable } from '@angular/core';
import { WebService } from './web.service';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { LanguageTranslateService } from './language-translate.service';
@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private setCom_details = new BehaviorSubject<any>([]);
  getComDetails = this.setCom_details.asObservable();
  private com_img = new BehaviorSubject<any>(null);
  com_logo$ = this.com_img.asObservable();

  constructor(
    private webService: WebService,
    private router: Router,
    private langService: LanguageTranslateService
  ) { }
  isCompanyUserLoggedIn() {
    return !!localStorage.getItem('hrmlix-uae-companyuser-token')
  }

  setComDetails(docs: any) {
    this.setCom_details.next(docs);
  }
  setComLogo(img: any) {
    this.com_img.next(img);
  }
  getCompanyUserAccountDetails() {
    return this.webService.post('company/get-company-data', {});
  }
  async companyUserLoggedIn(payload: any) {
    return this.webService.post('company_signin', payload)
  }
  async ProUserLoggedIn(payload: any) {
    return this.webService.post('pro_signin', payload)
  }
  getCompanyStaffAccountDetails() {
    return this.webService.post('company/get-account', {});
  }
  getUserDetails(){
    let user:any = localStorage.getItem('hrmlix-uae-companyuser-user');
    user = JSON.parse(user);
    return user;
  }
  logout() {
    console.log('dlogot');
    
    const lang = this.langService.getLangLocalStorage() ?? 'en';
    localStorage.removeItem('hrmlix-uae-companyuser-token');
    localStorage.removeItem('hrmlix-uae-companyuser-user');
    localStorage.removeItem('hrmlix-uae-companyuser-details');
    localStorage.removeItem('hrmlix-uae-companyuser-permission');
    console.log('to loginpage');
    
    this.router.navigate([`${lang}/login`]);
  }

  updatePassword(payload: any) {
    return this.webService.post('company/update-company-password', payload);
  }
}
